import React, {useMemo, useEffect, useRef} from 'react'
import {useHistory, useRouteMatch} from 'react-router'

import {GriffonItem} from '../store/types'

import Filters from './Filters'
import {SearchComponentType} from './Filters/Filters'
import ItemList from './ItemList'
import Layout from './Layout'
import FullWidthContainer from './LimitedMaxWidthContainer'
import UpgradeCTA from './UpgradeCTA'

import commonStyles from './common.module.scss'
import ItemListFooter from './ItemListFooter'

const CaptureNativeFind: React.FC<{onFind: () => void}> = ({onFind}) => {
  useEffect(() => {
    const keyDownHandler: EventListener = event => {
      const kEvent = event as KeyboardEvent

      if (
        kEvent.key.toLowerCase() === 'f' &&
        (kEvent.ctrlKey || kEvent.metaKey)
      ) {
        return
      }

      if (kEvent.key.match(/^[a-z]$/i)) {
        onFind()
      }
    }

    window.addEventListener('keydown', keyDownHandler)

    return () => {
      window.removeEventListener('keydown', keyDownHandler)
    }
  }, [onFind])

  return <div />
}

const Home: React.FC = () => {
  const history = useHistory()
  const searchComponentRef = useRef<SearchComponentType>(null)
  const abc = useRouteMatch()

  const navigateToItem = useMemo(
    () => (item: GriffonItem | string) => {
      history.push(`/items/${typeof item === 'string' ? item : item.id}`)
      if (searchComponentRef.current) searchComponentRef.current.blur()
    },
    [history],
  )

  const handleOnFind = () => {
    const searchComponent = searchComponentRef?.current
    if (searchComponent) {
      searchComponent.focus()
    }
  }

  return (
    <Layout fullWidth rootClassNames={commonStyles.headerWash} isHomePage>
      {abc.isExact ? <CaptureNativeFind onFind={handleOnFind} /> : null}
      <FullWidthContainer>
        <div className={commonStyles.pageHeader}>
          <img
            alt="The Ledger+"
            src="/images/page-header-home.png"
            className={commonStyles.pageHeaderImg}
          />
        </div>
        <Filters
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={searchComponentRef as any}
          listId="home"
          navigateToItem={navigateToItem}
          className="home-screen"
        />
        <UpgradeCTA />
        <ItemList
          listId="home"
          adminCanEditItems
          onItemClick={navigateToItem}
          footer={<ItemListFooter />}
        />
      </FullWidthContainer>
    </Layout>
  )
}

export default Home
